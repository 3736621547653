import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import i18nextHttpBackend from 'i18next-http-backend';
import dayjs from 'dayjs';

import { Locales, LocalesByCountryCode } from './types/locales.enum.ts';
import '../yup/yup.service.ts';

i18n
  .use(i18nextHttpBackend)
  .use(initReactI18next)
  .init({
    lng:
      localStorage.getItem('locale') ||
      LocalesByCountryCode[
        import.meta.env.VITE_ISO2_COUNTRY_CODE as 'SE' | 'NL' | 'UK'
      ],
    fallbackLng: ['en', 'sv', 'nl'],
    supportedLngs: ['en', 'sv', 'nl'],
    react: { useSuspense: true },
    load: 'languageOnly',
    ns: ['translation', 'validation'],
    defaultNS: 'translation',
    backend: {
      loadPath: (lng: string[], ns: string[]) => {
        if (ns[0] === 'partner') {
          return `/locales/partner/${lng[0]}/translation.json`;
        }
        return `/locales/client/${lng[0]}/${ns[0]}.json`;
      },
    },
  });

i18n.on('languageChanged', async (locale) => {
  switch (locale) {
    case Locales.sv: {
      await import('dayjs/locale/sv.js');
      break;
    }
    case Locales.nl: {
      await import('dayjs/locale/nl.js');
      break;
    }
  }
  dayjs.locale(locale);
  localStorage.setItem('locale', locale);
});

export default i18n;
export { i18n };
