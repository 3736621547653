import { FlowType } from '@/user/contexts/application/application-flows/types/flow.type.ts';
import instance from '@/user/services/api/instance.ts';
import { Guarantor } from '@/types/guarantor.type.ts';
import { File, FileType } from '@/types/file.type.ts';

const flow: FlowType = {
  guarantor: {
    url: '/client/factoring/guarantor',
    queryKey: 'guarantors',
    queryFn: (applicationId: string | undefined) =>
      instance.get(`/guarantor/all/${applicationId}`),
    getString: (data: Array<Guarantor>) => {
      if (!Array.isArray(data)) {
        return '';
      }

      return data
        .map(
          (object) =>
            `${object.user?.fullName || object.email} (${
              object.user?.ssn || object.phone
            })`,
        )
        .join(', ');
    },
    nextUrl: '/client/factoring/invoices-upload',
    backUrl: undefined,
    title: 'guarantor',
    description: 'guarantorDescription',
    isDataImportant: true,
  },
  invoicesUpload: {
    url: '/client/factoring/invoices-upload',
    queryKey: FileType.invoice,
    queryFn: (applicationId: string | undefined) =>
      instance.get(`/file/all/${applicationId}`, {
        params: {
          fileType: FileType.invoice,
        },
      }),
    getString: (data: Array<File>) => {
      if (!Array.isArray(data)) {
        return '';
      }

      return data.map((object) => object.fileName).join(', ');
    },
    title: 'invoicesUpload',
    nextUrl: '/client/factoring/balance-sheet',
    backUrl: '/client/factoring/guarantor',
    description: '',
    isDataImportant: true,
  },
  balanceSheet: {
    url: '/client/factoring/balance-sheet',
    queryKey: FileType.balanceSheet,
    queryFn: (applicationId: string | undefined) =>
      instance.get(`/file/all/${applicationId}`, {
        params: {
          fileType: FileType.balanceSheet,
        },
      }),
    getString: (data: Array<File>) => {
      if (!Array.isArray(data)) {
        return '';
      }

      return data.map((object) => object.fileName).join(', ');
    },
    title: 'balanceSheet',
    nextUrl: '/client/factoring/summary',
    backUrl: '/client/factoring/invoices-upload',
    description: '',
    isDataImportant: true,
  },
};
export default flow;
