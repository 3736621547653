import { Popper, Paper, ClickAwayListener } from '@mui/material';
import { Dispatch, SetStateAction } from 'react';
import { TPopperProps } from '@/shared/contexts/popper/types';

type PopperComponentType = TPopperProps & {
  setPopperProps: Dispatch<SetStateAction<TPopperProps>>;
};

const PopperComponent = (popperProps: PopperComponentType) => {
  const { children, setPopperProps, ...restProps } = popperProps;

  return (
    <ClickAwayListener onClickAway={() => setPopperProps(null)}>
      <Popper {...restProps}>
        <Paper>{children}</Paper>
      </Popper>
    </ClickAwayListener>
  );
};

export default PopperComponent;
