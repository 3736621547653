import { Dialog } from '@mui/material';
import { DialogContainerProps } from '@/shared/contexts/dialog/types';

const DialogContainerComponent = (props: DialogContainerProps) => {
  const { children, ...dialogProps } = props;

  return <Dialog {...dialogProps}>{children}</Dialog>;
};

export default DialogContainerComponent;
