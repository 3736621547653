import { createRoutesFromElements, RouteObject } from 'react-router-dom';

import PartnerRouteChildren from '@/partner/routes/routes.tsx';

const routesFromElements = createRoutesFromElements(
  PartnerRouteChildren,
  [...'partner'].map((char) => char.charCodeAt(0)),
);

const PartnerRoutes: RouteObject = {
  lazy: () => import('@/partner/layouts/refine.layout.tsx'),
  children: routesFromElements,
};
export default PartnerRoutes;
export { PartnerRoutes as Component };
