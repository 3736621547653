import DialogContainerComponent from '@/shared/components/dialog/dialog-container.component.tsx';
import { DialogContext } from '@/shared/contexts/dialog/dialog.context.tsx';
import { DialogParams } from '@/shared/contexts/dialog/types';
import { Dispatch, SetStateAction, useContext } from 'react';

type DialogProps = {
  dialogProps: DialogParams;
  setDialogs: Dispatch<SetStateAction<DialogParams[]>>;
};

const DialogComponent = ({ dialogProps, setDialogs }: DialogProps) => {
  const { onClose, ...dialogParams } = dialogProps;
  const { closeDialog } = useContext(DialogContext);

  const onExited = () => {
    if (dialogProps.onExited) {
      dialogProps.onExited();
    }

    setDialogs((dialogs: DialogParams[]) =>
      dialogs.slice(0, dialogs.length - 1),
    );
  };

  return (
    <DialogContainerComponent
      onClose={closeDialog}
      TransitionProps={{ onExited }}
      {...dialogParams}
    />
  );
};

export default DialogComponent;
