import { Navigate, RouteObject } from 'react-router-dom';
import flow from '@/user/contexts/application/application-flows/factoring.flow.ts';
import { lazy } from 'react';
import { FileType } from '@/types/file.type.ts';

const FileUploadPage = lazy(
  () => import('@/user/pages/factoring/file-upload/file-upload.page.tsx'),
);

const FactoringRoutes: RouteObject = {
  lazy: () => import('@/user/layouts/application/application.layout.tsx'),
  children: [
    {
      path: '/client/factoring/basic-info',
      lazy: () =>
        import('@/user/pages/factoring/basic-info/basic-info.page.tsx'),
    },
    {
      path: '/client/login',
      lazy: () => import('@/user/pages/auth/log-in/log-in.page.tsx'),
    },
    {
      lazy: () => import('@/user/guards/auth-guard/authenticated.guard.tsx'),
      children: [
        {
          path: flow.guarantor.url,
          lazy: () =>
            import('@/user/pages/factoring/guarantor/guarantor.page.tsx'),
        },
        {
          path: flow.invoicesUpload.url,
          Component: () => <FileUploadPage fileType={FileType.invoice} />,
        },
        {
          path: flow.balanceSheet.url,
          Component: () => <FileUploadPage fileType={FileType.balanceSheet} />,
        },
        {
          path: '/client/factoring/summary',
          lazy: () => import('@/user/pages/factoring/summary/summary.page.tsx'),
        },
        {
          path: '/client/factoring/minasidor',
          lazy: () =>
            import('@/user/pages/factoring/minasidor/minasidor.page.tsx'),
        },
      ],
    },
    {
      path: '/',
      element: <Navigate to="/client/factoring/basic-info" />,
    },
  ],
};
export default FactoringRoutes;
