import { PropsWithChildren, useState } from 'react';
import { DialogOptions, DialogParams } from '@/shared/contexts/dialog/types';
import { DialogContext } from '@/shared/contexts/dialog/dialog.context.tsx';
import DialogComponent from '@/shared/components/dialog/dialog.component.tsx';

const DialogProvider = ({ children }: PropsWithChildren) => {
  const [dialogs, setDialogs] = useState<DialogParams[]>([]);

  const createDialog = (options: DialogOptions) => {
    const dialog: DialogParams = { ...options, open: true };
    setDialogs((dialogs) => [...dialogs, dialog]);
  };

  const closeDialog = () => {
    setDialogs((dialogs) => {
      const latestDialog = dialogs.pop();
      if (!latestDialog) {
        return dialogs;
      }

      if (latestDialog.onClose) {
        latestDialog.onClose();
      }

      return [...dialogs].concat({ ...latestDialog, open: false });
    });
  };

  return (
    <DialogContext.Provider value={{ createDialog, closeDialog }}>
      {children}
      {dialogs.map((dialogProps, key) => (
        <DialogComponent
          key={key}
          dialogProps={dialogProps}
          setDialogs={setDialogs}
        />
      ))}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
