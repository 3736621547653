import * as yup from 'yup';
import { Flags, Schema } from 'yup';
import { t } from 'i18next';

declare module 'yup' {
  interface ArraySchema<
    /* eslint-disable  @typescript-eslint/no-explicit-any */
    TIn extends any[] | null | undefined,
    TContext,
    TDefault = undefined,
    TFlags extends Flags = '',
  > extends Schema<TIn, TContext, TDefault, TFlags> {
    unique(
      propertyName: string,
      message: string,
    ): ArraySchema<TIn, TContext, TDefault, TFlags>;
  }
}

const ns = 'validation';
yup.setLocale({
  mixed: {
    default: t('mixed.default', { ns }),
    required: t('mixed.required', { ns }),
    oneOf: ({ values }) => t('mixed.oneOf', { ns, values }),
    notOneOf: ({ values }) => t('mixed.notOneOf', { ns, values }),
    notNull: t('mixed.notNull', { ns }),
    notType: t('mixed.notType', { ns }),
    defined: t('defined', { ns }),
  },
  string: {
    length: ({ length }) => t('string.length', { length, ns }),
    min: ({ min }) => t('string.min', { min, ns }),
    max: ({ max }) => t('string.min', { max, ns }),
    matches: ({ regex }) => t('string.matches', { regex, ns }),
    email: ({ regex }) => t('string.email', { regex, ns }),
    url: ({ regex }) => t('string.url', { regex, ns }),
    uuid: ({ regex }) => t('string.uuid', { regex, ns }),
    trim: t('string.trim', { ns }),
    lowercase: t('string.lowercase', { ns }),
    uppercase: t('string.uppercase', { ns }),
  },
  number: {
    min: ({ min }) => t('number.min', { min, ns }),
    max: ({ max }) => t('number.max', { max, ns }),
    lessThan: ({ less }) => t('number.lessThan', { less, ns }),
    moreThan: ({ more }) => t('number.moreThan', { more, ns }),
    positive: ({ more }) => t('number.positive', { more, ns }),
    negative: ({ less }) => t('number.negative', { less, ns }),
    integer: t('number.integer'),
  },
  date: {
    min: ({ min }) => t('date.min', { min, ns }),
    max: ({ max }) => t('date.max', { max, ns }),
  },
  object: {
    noUnknown: t('object.noUnknown', { ns }),
  },
  array: {
    length: ({ length }) => t('array.length', { ns, length }),
    min: ({ min }) => t('array.min', { ns, min }),
    max: ({ max }) => t('array.min', { ns, max }),
  },
  boolean: {
    isValue: t('boolean.isValue', { ns }),
  },
});

yup.addMethod(yup.array, 'unique', function (propertyName, message) {
  return this.test('unique', message, function (value) {
    if (!propertyName) {
      return true;
    }

    if (!value || !Array.isArray(value) || value.length === 0) {
      return true;
    }

    const singleValueArray = value.map((item) => item[propertyName]);
    const valid = !singleValueArray.some((item, index) => {
      return singleValueArray.indexOf(item) !== index;
    });

    if (!valid) {
      return this.createError({
        path: propertyName,
        message: message,
        type: 'unique',
      });
    } else {
      return true;
    }
  });
});
