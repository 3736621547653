import React from 'react';
import ReactDOM from 'react-dom/client';

import '@/config/dayjs';
import '@/main.css';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import App from './App';

const root: HTMLElement | null = document.getElementById('root');
if (!root) {
  throw new TypeError('Missing root DOM element.');
}

ReactDOM.createRoot(root).render(
  <React.StrictMode>
    <link rel="preconnect" href={import.meta.env.VITE_BACKEND_URL_HOST} />
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <App />
    </LocalizationProvider>
  </React.StrictMode>,
);
