import { PropsWithChildren, useState } from 'react';
import { SnackbarContext } from '@/shared/contexts/snackbar/snackbar.context.tsx';
import { Slide, Snackbar, SnackbarProps } from '@mui/material';
import dayjs from 'dayjs';

const SnackbarProvider = ({ children }: PropsWithChildren) => {
  const [snackbars, setSnackBars] = useState<SnackbarProps[]>([]);

  const createSnackbar = (options: SnackbarProps) => {
    const snackbar: SnackbarProps = {
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
      autoHideDuration: dayjs.duration(2.5, 'seconds').asMilliseconds(),
      TransitionComponent: Slide,
      open: true,
      ...options,
    };
    setSnackBars((snackbars) => [...snackbars, snackbar]);
  };

  const closeSnackbar = () => {
    setSnackBars((snackbars) => {
      const latestDialog = snackbars.pop();
      if (!latestDialog) {
        return snackbars;
      }

      return [...snackbars];
    });
  };

  return (
    <SnackbarContext.Provider value={{ createSnackbar, closeSnackbar }}>
      {children}
      {snackbars.map((snackbarProps, key) => (
        <Snackbar
          key={key}
          {...snackbarProps}
          onClose={() => {
            closeSnackbar();
          }}
        />
      ))}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
