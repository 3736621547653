import { Application } from '@/types/application.type.ts';

export enum FileType {
  invoice = 'Invoice',
  balanceSheet = 'Balance sheet',
}

export type File = {
  _id?: string;
  application: Application;
  fileType: FileType;
  destination: string;
  fileName: string;
  mimeType?: string;
  dateFrom?: Date;
  dateTo?: Date;
  description?: string;
  updatedAt: Date;
  createdAt: Date;
};
