import { createContext } from 'react';
import { PopperContextType } from '@/shared/contexts/popper/types';

export const PopperContext = createContext<PopperContextType>({
  closePopper: () => {
    throw new Error('Not implemented');
  },
  createPopper: () => {
    throw new Error('Not implemented');
  },
});
