import axios, { AxiosError, AxiosResponse } from 'axios';
import dayjs from '@/config/dayjs/index.ts';
import { BackendError } from '@/types/backend-error.type.ts';

const instance = axios.create({
  baseURL: `${import.meta.env.VITE_BACKEND_URL_HOST as string}/api`,
  withCredentials: true,
  timeout: dayjs.duration(5, 'seconds').asMilliseconds(),
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': import.meta.env
      .VITE_BACKEND_URL_HOST as string,
  },
});

instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response?.data;
  },
  (error: AxiosError<BackendError>): BackendError | Response => {
    throw error.response?.data;
  },
);

export default instance;
