import { PropsWithChildren, useState } from 'react';
import { PopperContext } from '@/shared/contexts/popper/popper.context.tsx';
import PopperComponent from '@/shared/components/popper/popper.component.tsx';
import { TPopperProps } from '@/shared/contexts/popper/types';

const PopperProvider = ({ children }: PropsWithChildren) => {
  const [popperProps, setPopperProps] = useState<TPopperProps>();

  const closePopper = () => setPopperProps(null);

  return (
    <PopperContext.Provider
      value={{ createPopper: setPopperProps, closePopper }}
    >
      {children}
      {popperProps && (
        <PopperComponent {...popperProps} setPopperProps={setPopperProps} />
      )}
    </PopperContext.Provider>
  );
};

export default PopperProvider;
