export enum Locales {
  en = 'en',
  sv = 'sv',
  nl = 'nl',
}

export enum LocalesByCountryCode {
  SE = 'sv',
  NL = 'nl',
  UK = 'en',
}
