import { createContext } from 'react';
import { DialogContextType } from '@/shared/contexts/dialog/types';

export const DialogContext = createContext<DialogContextType>({
  closeDialog: () => {
    throw new Error('Not implemented');
  },
  createDialog: () => {
    throw new Error('Not implemented');
  },
});
