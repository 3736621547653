import { RouterProvider } from 'react-router-dom';

import Router from '@/router.tsx';
import SnackbarProvider from '@/shared/contexts/snackbar/snackbar.provider.tsx';
import PopperProvider from '@/shared/contexts/popper/popper.provider.tsx';
import DialogProvider from '@/shared/contexts/dialog/dialog.provider.tsx';

const App: React.FC = () => {
  return (
    <SnackbarProvider>
      <PopperProvider>
        <DialogProvider>
          <RouterProvider router={Router} />
        </DialogProvider>
      </PopperProvider>
    </SnackbarProvider>
  );
};

export default App;
