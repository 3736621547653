import { Route } from 'react-router-dom';
import { lazy } from 'react';

const NavigateToResource = lazy(() =>
  import('@refinedev/react-router-v6').then((module) => ({
    default: module.NavigateToResource,
  })),
);

const MuiInferencer = lazy(() =>
  import('@refinedev/inferencer/mui').then((module) => ({
    default: module.MuiInferencer,
  })),
);

const Dashboard = lazy(() => import('@/partner/pages/dashboard/dashboard.tsx'));
const ApplicationList = lazy(
  () => import('@/partner/pages/applications/list.tsx'),
);
const ApplicationShow = lazy(
  () => import('@/partner/pages/applications/show.tsx'),
);
const ApplicationCreate = lazy(
  () => import('@/partner/pages/applications/create/create.tsx'),
);
const AuthTitle = lazy(
  () => import('@/partner/components/layout/auth-title.tsx'),
);
const AuthPage = lazy(
  () => import('@/partner/components/pages/auth/auth-page'),
);

const AuthenticatedGuard = lazy(
  () => import('@/partner/guards/authenticated.guard.tsx'),
);

const NotAuthenticatedGuard = lazy(
  () => import('@/partner/guards/not-authenticated.guard.tsx'),
);

const PartnerRoutes = (
  <Route path="/">
    <Route element={<AuthenticatedGuard />}>
      <Route path="/partner/dashboard">
        <Route index element={<Dashboard />} />
      </Route>
      <Route path="/partner/open_applications">
        <Route index element={<ApplicationList />} />
        <Route
          path="/partner/open_applications/show/:id"
          element={<ApplicationShow />}
        />
        <Route
          path="/partner/open_applications/create"
          element={<ApplicationCreate />}
        />
      </Route>
      <Route path="/partner/closed_applications">
        <Route index element={<ApplicationList />} />
        <Route
          path="/partner/closed_applications/show/:id"
          element={<ApplicationShow />}
        />
        <Route
          path="/partner/closed_applications/create"
          element={<MuiInferencer />}
        />
      </Route>
      <Route path="/partner/signed_applications">
        <Route index element={<ApplicationList />} />
        <Route
          path="/partner/signed_applications/show/:id"
          element={<ApplicationShow />}
        />
        <Route
          path="/partner/signed_applications/create"
          element={<MuiInferencer />}
        />
      </Route>
      <Route path="/partner/draft_applications">
        <Route index element={<ApplicationList />} />
        <Route
          path="/partner/draft_applications/show/:id"
          element={<ApplicationShow />}
        />
        <Route
          path="/partner/draft_applications/create"
          element={<ApplicationCreate />}
        />
        <Route
          path="/partner/draft_applications/edit/:id"
          element={<MuiInferencer />}
        />
      </Route>
      <Route
        path="/partner/applications/create"
        element={<ApplicationCreate />}
      />
      <Route
        path="/partner/draft_applications/create"
        element={<ApplicationCreate />}
      />
      <Route
        path="/partner/*"
        element={<NavigateToResource resource="dashboard" />}
      />
    </Route>
    <Route element={<NotAuthenticatedGuard />}>
      <Route
        path="/partner/login"
        element={
          <AuthPage
            type="login"
            title={<AuthTitle text="Partner platform" collapsed={false} />}
          />
        }
      />
      <Route
        path="/partner/register"
        element={
          <AuthPage
            type="register"
            title={<AuthTitle text="Partner platform" collapsed={false} />}
          />
        }
      />
      <Route
        path="/partner/forgot-password"
        element={
          <AuthPage
            type="forgotPassword"
            title={<AuthTitle text="Partner platform" collapsed={false} />}
          />
        }
      />
      <Route
        path="/partner/password/forgot"
        element={
          <AuthPage
            type="updatePassword"
            title={<AuthTitle text="Partner platform" collapsed={false} />}
          />
        }
      />
    </Route>
  </Route>
);

export default PartnerRoutes;
export { PartnerRoutes as Component };
