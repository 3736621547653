import { createContext } from 'react';
import { SnackbarContextType } from '@/shared/contexts/snackbar/types';

export const SnackbarContext = createContext<SnackbarContextType>({
  closeSnackbar: () => {
    throw new Error('Not implemented');
  },
  createSnackbar: () => {
    throw new Error('Not implemented');
  },
});
