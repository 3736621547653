import { RouteObject } from 'react-router-dom';
import '@/user/services/i18next/i18next.service.tsx';

const AdminRoutes: RouteObject = {
  lazy: () => import('@/admin/layouts/default/default.layout.tsx'),
  children: [
    {
      path: '/admin/login',
      lazy: () => import('@/admin/pages/login/login.page.tsx'),
    },
    {
      lazy: () => import('@/admin/guards/auth-guard/authenticated.guard.tsx'),
      children: [
        {
          path: '/admin/applications',
          lazy: () =>
            import('@/admin/pages/applications/applications.page.tsx'),
        },
        {
          path: '/admin/applications/:id',
          lazy: () => import('@/admin/pages/applications/application.page.tsx'),
        },
      ],
    },
  ],
};
export default AdminRoutes;
